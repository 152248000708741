/*
  ```
    // typical yes/no confirm dialog

    DrbConfirm.open({
      title: 'Are you sure?',
      message: 'This will notify your client that they need to review the project and release funds.',
      onConfirm: (confirmDialog) => {
        // do some action
      },
    });

    // confirmation dialog with icon & single action button

    import checkIcon from '~/assets/icons/check-verified.svg?raw';

    DrbConfirm.open({
      centered: true,
      iconHtml: checkIcon,
      title: 'Hooray! Your project is complete and you’ve been paid!',
      confirmText: 'View Transaction',
      onConfirm: (confirmDialog) => {
        // do some action
      },
    });
  ```
*/

export const DrbConfirm = {
  open({
    id = null,
    iconHtml = null,
    iconInline = false,
    title = 'Are you sure?',
    message = null,
    centered = false,
    confirmText = 'Confirm',
    confirmHref = '',
    confirmClass = 'btn2--primary',
    cancelText = 'Cancel',
    cancelHref = '',
    cancelClass = 'btn2--tertiary',
    onConfirm = () => {},
    onCancel = () => {},
    footerHtml = '',
  }) {
    const confirmDialog = document.createElement('drb-confirm');

    if (id) confirmDialog.id = id;
    if (message) confirmDialog.message = message;
    confirmDialog.iconHtml = iconHtml;
    confirmDialog.iconInline = iconInline;
    confirmDialog.title = title;
    confirmDialog.centered = centered;
    confirmDialog.confirmText = confirmText;
    confirmDialog.confirmHref = confirmHref;
    confirmDialog.confirmClass = confirmClass;
    confirmDialog.cancelText = cancelText;
    confirmDialog.cancelHref = cancelHref;
    confirmDialog.cancelClass = cancelClass;
    confirmDialog.onConfirm = onConfirm;
    confirmDialog.onCancel = onCancel;
    confirmDialog.footerHtml = footerHtml;

    document.body.appendChild(confirmDialog);
  },
};
